<template>
    <main class="unit2">
        <router-view
            class="unit2__data"
            :reportDaterange="reportDaterange"
            :compareDaterange="compareDaterange"
            :userRestaurants="userRestaurants"
            :userUnitOrigins="userUnitOrigins"
        />
    </main>
</template>

<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "Unit2",
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.unit2.compareDaterange
            }),
            ...mapGetters([
                "userRestaurants",
                "userUnitOrigins"
            ])
        }
    }
</script>
